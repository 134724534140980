import { toast } from "react-toastify";
import { useQuery, useMutation } from "react-query";

import { createAxiosInstance, ApiErrorProps } from "api/utils";

import { USERS_API_KEY, USERS_PUBLICATIONS_API_KEY } from "../keys";
import {
  USERS_ACKNOWLEDGE_API_ENDPOINT,
  USERS_API_ENDPOINT,
  USERS_BY_ID_API_ENDPOINT,
  USERS_PUBLICATIONS_BY_ID_API_ENDPOINT,
} from "../endpoints";
import {
  GetUserById,
  GetUserPublicationsById,
  PostUser,
  PostUserActivation,
} from "../models";
import { PostUserAcknowledge } from "api/models/Users/PostUserAcknowledge";

const AxiosInstance = createAxiosInstance();

/**
 *  //*GET All Users
 */
const fetchAllUsers = async () => {
  return AxiosInstance.get(USERS_API_ENDPOINT).catch((error) => {
    const { errors } = error.response?.data as ApiErrorProps;
    errors?.length > 0
      ? toast.error(errors[0].detail)
      : toast.error(error.response.statusText);
    throw error;
  });
};

export const useFetchAllUsers = () => {
  return useQuery([USERS_API_KEY], fetchAllUsers);
};

/**
 *  //*GET User By Id
 */
const fetchUserById = async (userId: string) => {
  return AxiosInstance.get<GetUserById.ApiResponse>(
    USERS_BY_ID_API_ENDPOINT(userId)
  ).catch((error) => {
    const { errors } = error.response?.data as ApiErrorProps;
    errors?.length > 0
      ? toast.error(errors[0].detail)
      : toast.error(error.response.statusText);
    throw error;
  });
};

export const useFetchUserById = (userId: string, enabled = false) => {
  return useQuery([USERS_API_KEY, userId], () => fetchUserById(userId), {
    enabled,
  });
};

/**
 *  //*GET User Publications By Id
 */
const fetchUserPublicationsById = async (userId: string) => {
  return AxiosInstance.get<GetUserPublicationsById.ApiResponse>(
    USERS_PUBLICATIONS_BY_ID_API_ENDPOINT(userId)
  ).catch((error) => {
    // do not display toast for this api, since the GET userById api would've toasted if api is down
    throw error;
  });
};

export const useFetchUserPublicationsById = (
  userId: string,
  enabled = false
) => {
  return useQuery(
    [USERS_PUBLICATIONS_API_KEY, userId],
    () => fetchUserPublicationsById(userId),
    {
      enabled,
    }
  );
};

/**
 *  //*POST Users (Register user, DEPRECATED)
 */
const submitUser = async (data: PostUser.PayLoad) => {
  return AxiosInstance.post<PostUser.ApiResponse>(
    USERS_API_ENDPOINT,
    data
  ).catch((error) => {
    const { errors } = error.response?.data as ApiErrorProps;
    errors?.length > 0
      ? toast.error(errors[0].detail)
      : toast.error(error.response.statusText);
    throw error;
  });
};

export const useSubmitUser = () => {
  return useMutation(submitUser);
};

/**
 *  //*POST Users
 */
const submitUserActivation = async (data: PostUserActivation.PayLoad) => {
  return AxiosInstance.post<PostUser.ApiResponse>(
    USERS_API_ENDPOINT,
    data
  ).catch((error) => {
    const { errors } = error.response?.data as ApiErrorProps;
    errors?.length > 0
      ? toast.error(errors[0].detail)
      : toast.error(error.response.statusText);
    throw error;
  });
};

export const useSubmitUserActivation = () => {
  return useMutation(submitUserActivation);
};

/**
 *  //*POST User Acknowledge
 */
const submitUserAcknowledge = async () => {
  return AxiosInstance.post<PostUserAcknowledge.ApiResponse>(
    USERS_ACKNOWLEDGE_API_ENDPOINT
  ).catch((error) => {
    const { errors } = error.response?.data as ApiErrorProps;
    errors?.length > 0
      ? toast.error(errors[0].detail)
      : toast.error(error.response.statusText);
    throw error;
  });
};

export const useSubmitUserAcknowledge = (onSuccess?: () => void) => {
  return useMutation(submitUserAcknowledge, {
    onSuccess,
  });
};
