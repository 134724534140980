export const USERS_API_KEY = "users";
export const USERS_PUBLICATIONS_API_KEY = "users_publications";

export const ME_API_KEY = "me";

export const USERS_BY_ADMIN_API_KEY = "users_by_admin";
export const PENDING_USERS_BY_ADMIN_API_KEY = "pending_users_by_admin";

export const CONNECTIONS_API_KEY = "connections";

export const DEPARTMENTS_API_KEY = "departments";

export const SEARCH_USERS_API_KEY = "search_users";
export const SEARCH_MEDICAL_KEYWORDS_API_KEY = "search_medical_keywords";
export const SEARCH_PUBLICATIONS_API_KEY = "search_publications";

export const METADATA_DESIGNATIONS_API_KEY = "metadata_designations";
export const METADATA_INSTITUTIONS_API_KEY = "metadata_institutions";
export const METADATA_SPECIALTIES_API_KEY = "metadata_specialties";
export const METADATA_RESEARCH_INTERESTS_API_KEY =
  "metadata_research_interests";
export const METADATA_PATIENT_POOLS_API_KEY = "metadata_patient_pools";

export const PUBLICATIONS_API_KEY = "publications";
export const PUBLICATIONS_EXPORT_PDF_API_KEY = "publications_export_pdf";

export const STUDIES_API_KEY = "studies";

export const PUBMED_NAMES_API_KEY = "pubmed_names";

export const CLINICAL_TRIALS_GOV_API_KEY = "clinical_trials_gov";
