import { ImgXMarkOutline } from "assets";

interface ModalProps {
  title?: string;
  content: React.ReactNode;
  isVisible: boolean;
  onDismiss: () => void;
}

const Modal = ({ title, content, isVisible, onDismiss }: ModalProps) => {
  return (
    <div
      className={`transition-all duration-500 z-50 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        onClick={onDismiss}
        className={`w-[100vw] top-0 left-0 h-[100vh] fixed opacity-80 bg-black z-40 ${
          isVisible ? "pointer-events-auto" : "pointer-events-none"
        }`}
      />
      <div
        className={`shadow-lg flex flex-col duration-500 left-1/2 top-1/2 -translate-x-1/2 transition-all z-40 ${
          isVisible
            ? "pointer-events-auto -translate-y-1/2 opacity-100"
            : "pointer-events-none translate-y-full opacity-0"
        } p-8 fixed m-0 w-full md:w-auto max-h-[90vh] max-w-[90vw] md:min-w-[75vw] md:max-w-[80vw] lg:min-w-[50vw] rounded-md z-50 bg-white overflow-auto`}
      >
        {title && (
          <h2 className="mb-2 text-3xl font-bold text-center">{title}</h2>
        )}
        <ImgXMarkOutline
          onClick={onDismiss}
          width={25}
          height={25}
          className="fixed cursor-pointer top-5 right-5"
        />
        {content}
      </div>
    </div>
  );
};

export default Modal;
