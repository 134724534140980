import { FormSelectModel } from "models";

export const searchInOptions: FormSelectModel[] = [
  {
    id: "researcherName",
    name: "Researcher Name",
    type: "preset",
  },
  {
    id: "medicalKeywords",
    name: "Medical Keywords",
    type: "preset",
  },
  {
    id: "publications",
    name: "Publications",
    type: "preset",
  },
];
