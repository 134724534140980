export const getOnboardingSteps = () => {
  return [
    {
      number: 1,
      description: "Basic Information",
    },
    {
      number: 2,
      description: "Research Interests",
    },
    {
      number: 3,
      description: "Patient Populations",
    },
    {
      number: 4,
      description: "Publications",
    },
  ];
};
