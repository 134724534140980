import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ImgBars4Outline, ImgXMarkOutline, imgAppLogo } from "assets";
import { navigateToSubdomain } from "utils";

const TopNav = () => {
  return (
    <Popover className="relative bg-white">
      <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div
          className="flex items-center justify-start lg:w-0 lg:flex-1 cursor-pointer"
          onClick={() => {
            const url = window.location.href
              .replace("app.", "")
              .replace("/login", "");
            return (window.location.href = url);
          }}
        >
          <img
            className="h-10 w-auto sm:h-12 hover:rotate-[360deg] transition-all duration-1000"
            src={imgAppLogo}
            alt="app_logo"
          />

          <span className="ml-2 text-xl lg:text-2xl font-semibold">ReData</span>
        </div>
        <div className="-my-2 -mr-2 md:hidden">
          <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
            <span className="sr-only">Open menu</span>
            <ImgBars4Outline className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>

        <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
          <a
            href={navigateToSubdomain("app", "/login")}
            className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 transition duration-300 hover:scale-[1.03]"
          >
            Sign In
          </a>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-20"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div className="flex space-x-2 items-center">
                  <img
                    className="h-10 w-auto sm:h-12 hover:rotate-[360deg] transition-all duration-1000"
                    src={imgAppLogo}
                    alt="app_logo"
                  />
                  <span className="ml-2 text-xl lg:text-2xl font-semibold">
                    ReData
                  </span>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">Close menu</span>
                    <ImgXMarkOutline className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="mt-6">
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing user?{" "}
                  <a
                    href={navigateToSubdomain("app", "/login")}
                    className="text-primary-600 hover:text-primary-500"
                  >
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default TopNav;
