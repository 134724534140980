export const validationMessages = {
  require: {
    confirmPassword: "Confirm password is required",
    pubmedName: "PubMed name is required",
    department: "Department is required",
    designation: "Designation is required",
    email: "Email is required",
    generic: "This field is required",
    institution: "Institution is required",
    name: "Name is required",
    password: "Password is required",
    primarySpecialty: "Primary specialty is required",
  },
  validate: {
    confirmPassword: "Passwords must match",
    email: "Please enter a valid email",
    max50: "Please keep this field below 50 characters",
    max1500: "Please keep this field below 1500 characters",
    pubmedName: "Please enter at least 1 first and last name",
    duplicateSubSpecialty: "Please enter different sub-specialties",
    duplicateResearchInterest: "Please enter different research interests",
    duplicatePatientPopulation: "Please enter different patient populations",
    searchIn: "Please select at least 1 field to search",
    tryAnotherKeyword: "There are no results associated with this keyword",
  },
};
